import React, { useEffect, useRef, useState } from 'react'
import { IWorkBannerProps } from './types'
import Image from '@/components/base/image'
import Video from '@/components/base/video/Video'
import { default as NextImage } from 'next/image'
import { useApp, useMobile, useTablet, path } from '@wap-client/core'
import Anchor from '@/components/base/anchor/Anchor'

const WorkBanner: React.FC<IWorkBannerProps> = ({
  video,
  image,
  hoverPlay = false,
  anchor,
}) => {
  const app = useApp()
  const isMobile = useMobile(app.headers['user-agent'])
  const isTablet = useTablet(app.headers['user-agent'])
  const [videoPlay, setVideoPlay] = useState<boolean>(false)
  const [isLoginMobile, setIsLoginMobile] = useState<boolean>(
    isMobile || isTablet ? true : false
  )
  const refDOMVideo = useRef<HTMLVideoElement>(null)

  const playVideo = () => {
    refDOMVideo.current?.play()
  }

  const pauseVideo = () => {
    refDOMVideo.current?.pause()
  }

  const handleVideoEvent = (e: React.SyntheticEvent) => {
    e.preventDefault()

    setVideoPlay((videoPlay) => !videoPlay)
  }

  useEffect(() => {
    videoPlay ? playVideo() : pauseVideo()
  }, [videoPlay])

  const videoSettings = {
    autoPlay: false,
    controls: false,
    loop: true,
    muted: true,
    playsInline: true,
    preload: 'none',
  }

  const videoIcons = {
    play: (
      <NextImage
        src="/icons/video-play.svg"
        alt="Video Icon"
        fill
        onClick={(e) => handleVideoEvent(e)}
      />
    ),
    pause: (
      <NextImage
        src="/icons/video-pause.svg"
        alt="Video Icon"
        fill
        onClick={(e) => handleVideoEvent(e)}
      />
    ),
  }

  return (
    <Anchor {...anchor} className="work-banner">
      {video?.src ? (
        <div className="work-banner-video">
          {isLoginMobile || !hoverPlay ? (
            <>
              <Video
                {...video}
                {...videoSettings}
                poster={
                  video?.poster
                    ? path.asset(app.environment, video.poster)
                    : path.asset(app.environment, image?.src)
                }
                ref={refDOMVideo}
              />
              <button className="video-icon-button">
                {videoPlay ? videoIcons.pause : videoIcons.play}
              </button>
            </>
          ) : (
            <Video
              {...video}
              {...videoSettings}
              poster={
                video?.poster
                  ? path.asset(app.environment, video.poster)
                  : path.asset(app.environment, image?.src)
              }
              ref={refDOMVideo}
              onMouseEnter={playVideo}
              onMouseLeave={pauseVideo}
            />
          )}
        </div>
      ) : (
        <Image loading="eager" {...image} alt="Project Image" />
      )}
    </Anchor>
  )
}

export default WorkBanner
