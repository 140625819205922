import React from 'react'
import { IHeadlineWorkList } from './types'

import { Column, Row } from '@/components/base/gridview'
import Icon from '@/components/base/icon'
import Anchor from '@/components/base/anchor/Anchor'
import { useApp } from '@wap-client/core'

const HeadlineWorkList: React.FC<IHeadlineWorkList> = ({
  title,
  desc,
  results,
  category,
}) => {
  const app = useApp()

  return (
    <React.Fragment>
      <div className="headline-work-list">
        <Row className="headline-worklist-header">
          <h3 className="headline-worklist-header-title">{title}</h3>
        </Row>
        <Row className="headline-worklist-category">
          <Icon name="info" />
          <Column xs={{ size: 12 }} xl={{ size: 6 }}>
            <div className="category">
              {category?.slice(0, 2)?.map((category, index) => (
                <Anchor
                  href={`${app.settings.routes['works-refs'].href}?category=${category.id}`}
                  key={index}
                  className="category-item"
                >
                  {category.title}
                </Anchor>
              ))}
            </div>
          </Column>
          <Column xs={{ size: 12 }} xl={{ size: 6 }}>
            <p className="headline-worklist-category-desc">{desc}</p>
          </Column>
        </Row>
        <Row className="headline-worklist-property">
          <Icon name="statistics" />
          <Column xs={{ size: 11 }}>
            <Row>
              {results?.map((result, index) => (
                <Column xs={{ size: 4 }} key={index} className="result">
                  <span className="result-number">{result?.deger}</span>
                  <span className="result-title">{result?.baslik}</span>
                </Column>
              ))}
            </Row>
          </Column>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default HeadlineWorkList
