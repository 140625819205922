import React, { useState } from 'react'
import { IWork, IWorkListProps } from './types'
import HeadlineWorkList from '../headline-work-list/HeadlineWorkList'
import { Column, Container, Row } from '@/components/base/gridview'
import { functions, path, useApp } from '@wap-client/core'
import Anchor from '@/components/base/anchor'
import WorkBanner from '@/components/widgets/work-banner'

const WorkList: React.FC<IWorkListProps> = ({
  data = [],
  hoverPlay,
  count = 4,
}) => {
  const projects = data?.slice(0, count)
  const app = useApp()

  return (
    <>
      {projects?.map((project, index) => {
        return (
          <div
            key={index}
            className={functions.classnames(
              index === projects.length - 1 && 'view-all'
            )}
          >
            <div className="project-image">
              <WorkBanner
                image={project?.image}
                video={project?.video}
                hoverPlay={hoverPlay}
                anchor={project.link}
              />
            </div>
            <Container
              size="extended"
              className={functions.classnames(
                index === projects.length - 1 && 'minimized-content'
              )}
            >
              <HeadlineWorkList
                title={project?.title}
                desc={project?.description}
                category={project?.category}
                results={project?.property}
              />
            </Container>
            {index === projects.length - 1 && (
              <Anchor className="view-all-button" href="works">
                {app.settings.translations['allProjects']}
              </Anchor>
            )}
          </div>
        )
      })}
    </>
  )
}

export default WorkList
